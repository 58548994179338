// Here you can add other styles

a{
    color: #1f6db5;
    text-decoration:none;
  
    &:visited{ 
      text-decoration: none;
      opacity: 0.82;
    }
    &:hover{
      color: #2e89de;
      opacity: 0.82;        
      text-decoration: underline;
    }
    &:active{
      opacity: 1;
      text-decoration: none;
    }    
}