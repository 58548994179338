// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "~ngx-toastr/toastr";

@import "~jsoneditor/dist/jsoneditor.min.css";

button:focus {
  outline: none !important;
}
